<template>
  <div class="lightspotbox">
    <div class="img_header"><img :src="encnimg[num].imgurl" alt="" /></div>
    <div class="fs30">{{encnimg[num].title}}</div>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item
        :title="item[num].title"
        :name="index"
        v-for="(item, index) in activeNamesList"
        :key="index"
      >
        <div>
          {{ item[num].textdata }}
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  methods: {
    handleChange() {
      // console.log(val);
    },
  },
  data() {
    return {
      encnimg:[
        { 
          title:'九大亮点',
          imgurl:require('../../img/headerimg.png')
        },
        { 
          title:'9 Highlights',
          imgurl:require('../../img/headerenimg.png')
        }
      ],
      activeNames: ['1'],
      
      activeNamesList:[
        {
          0:{
            title:'01 永久产权',
            textdata:'仅凭护照就可永久持有的低总价住宅项目。'
          },
          1:{
            title:'01 Freehold',
            textdata:'Full ownership of the property for both non-Thai and Thai citizens.'
          }
        },
        {
          0:{
            title:'02 最大体量',
            textdata:'4层12米高，带电梯花园洋房，低密度、永久产权精装住宅。全岛最大体量：533套住宅。'
          },
          1:{
            title:'02 Mega Project',
            textdata:'With 533 units of condo, Wing Samui Condo is the largest and the latest condominium project in Koh Samui.'
          }
        },
        {
          0:{
            title:'03 黄金区位',
            textdata:'住宅位于苏梅岛最繁华的查汶区，配套醇熟。西邻热带山林、东邻苏梅国际机场、北靠富人区，是苏梅最具有价值的黄金三角区。'
          },
          1:{
            title:'03 Prime Location',
            textdata:'Conveniently located in the center of Koh Samui, Chaweng, the most famous and popular tourist area, with the strongest rental demand. Close to Chaweng beach, shopping malls, airport, international schools and hospitals.'
          }
        },
        {
          0:{
            title:'04 醇熟配套',
            textdata:'社区空地面积高达5100余平，配套设施包含网球场、泳池、儿童泳池/乐园、健身房、夜光跑道等，满足您的生活所需。'
          },
          1:{
            title:'04  High Class Amenities',
            textdata:'Swimming Pool/Tennis Court/Fitness Gym Kids Pool/Kids Club Playground 5100+ sqm Garden/Outdoor Jogging Track.'
          }
        },
        {
          0:{
            title:'05 独特设计',
            textdata:'单侧排布，“风谷”气候设计，自然舒适。住宅楼栋学习国际顶级大师Charles Correa气候设计理念，利用单侧走廊、内中庭策略回应热带气候特点产生气压差，促使空气流动形成自然穿堂风天然降温，创造出更节能健康的热带宜居生态。'
          },
          1:{
            title:'05 Superb Comfort Design',
            textdata:'Utilise the climate design concept by Charles Correa which emphasizes on the natural circulation of air and ventilation throughout the spaces, thus being both eco-friendly and practical.'
          }
        },
        {
          0:{
            title:'06 价值洼地',
            textdata:'一带一路南线价值洼地，苏梅岛作为一带一路曼谷连接马来西亚和新加坡的必经之地，其经济发展潜力以及优质的自然旅游资源必然会被更多人发掘，而其游客密度相较于普吉岛仍有5倍增长空间，升值空间巨大。'
          },
          1:{
            title:'06 Ultimate Holiday Destination',
            textdata:'The most popular holiday destination for European, Koh Samui, the second largest island in Thailand, is globally known and its number of tourists growing drastically every year. From 2010 arrivals at Samui international airport had steadily increased reaching a peak of 1,325,251 passengers in 2019, up substan- tially over the 6 years from 2013.'
          }
        },
        {
          0:{
            title:'07 需求旺盛',
            textdata:'苏梅岛年游客数量复合增长超过20%，70%为欧美游客，平均在苏梅岛停留一个月以上。'
          },
          1:{
            title:'07  75% occupancy',
            textdata:`Koh Samui's rental market is in high demand dueto year round tourism. Holiday rental propertieson average can achieve up to a 75% occupancy.`
          }
        },
        {
          0:{
            title:'08 五年回购',
            textdata:'羽翼苏梅业主在房屋交付的第五年起，每年有一次向开发商申请原价回购的权利。'
          },
          1:{
            title:'08 Buyback After 5 Years',
            textdata:'Guaranteed buyback option for all units purchased.'
          }
        },
        {
          0:{
            title:'09 全球换住',
            textdata:'长租式住宅托管服务，租赁情况实时可查，并可换住全球100多个目的地城市。'
          },
          1:{
            title:'09 Rental Rewards Program',
            textdata:'Rental management service. Allows you to check real-time rental status on your phone, earn points by rental and use them for hotel stays in more than 100 worldwide destinations.'
          }
        }
      ]
    }
  },
};
</script>

<style lang="less" scoped>
.el-collapse-item{
    margin-top: 0.15rem;

}
.el-collapse{
  border: none;
}
/deep/.el-collapse-item__content div{
line-height: 0.35rem !important;
padding: 0 0.30rem;
}
/deep/.el-collapse-item__header{
    height: 0.7rem !important;
    color: #b5a897 !important;
    font-size: 0.20rem !important;
    text-indent: 0.5rem;
}
.lightspotbox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin-top: 0.8rem;
  margin-bottom:0.2rem ;
  font-size: 0.3rem;
  text-align: center;
}
.fs17 {
  margin-top: 0.3rem;
  font-size: 0.17rem;
  text-align: center;
  font-weight: 300;
}
</style>